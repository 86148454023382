.glide__arrow {
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 .25em .5em #0000001a;
  opacity: 1;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ffffff80;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: #fff;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: .33;
}

.glide__bullets {
  z-index: 2;
  list-style: none;
  display: inline-flex;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.glide__bullet {
  cursor: pointer;
  background-color: #ffffff80;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 .25em;
  padding: 0;
  line-height: 0;
  transition: all .3s ease-in-out;
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  background-color: #ffffff80;
  border: 2px solid #fff;
}

.glide__bullet--active {
  background-color: #fff;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
/*# sourceMappingURL=index.923b25f9.css.map */
